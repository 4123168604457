import { z } from 'zod';

const zCurrencyCodeSchema = z.enum([
  'USD', // United States Dollar
  'EUR', // Euro
  'JPY', // Japanese Yen
  'GBP', // British Pound Sterling
  'AUD', // Australian Dollar
  'CAD', // Canadian Dollar
  'CHF', // Swiss Franc
  'CNY', // Chinese Yuan
  'SEK', // Swedish Krona
  'NZD', // New Zealand Dollar
  // Add more currencies as needed
]);

export type CurrencyCode = z.infer<typeof zCurrencyCodeSchema>;

export const moneyValue = (
  value: number,
  digits: number = 2,
  currency: CurrencyCode = 'USD',
  locale: string = 'en-US',
) => {
  try {
    const formatter = new Intl.NumberFormat(locale, {
      style: 'currency',
      currency,
      minimumFractionDigits: digits,
      maximumFractionDigits: digits,
    });
    return formatter.format(value);
  } catch (error) {
    return value.toFixed(2);
  }
};
